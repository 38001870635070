
import { defineComponent, PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
import AppActions from '@constants/AppActions'
import { isExternalLink, trackLinkedinAds } from '@helpers/helpers'

export default defineComponent({
  name: 'AcquisitionHero',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      isExternalLink,
    }
  },
  components: {},
  computed: {
    heroButtonLink() {
      let link = this.block?.ctaButton?.link
      if (!this.userIsAuthenticated) {
        link = link + '/register'
      }
      return link
    },
    userIsAuthenticated() {
      return this.$store.getters['userIsAuthenticated']
    },
    commonHeroImageLoading(): string {
      return process.client && window && window.innerWidth <= 1230 ? 'lazy' : ''
    },
  },
  methods: {
    async heroButtonClicked() {
      if (this.block?.ctaButton?.enableTracking) {
        if (
          this.block?.ctaButton?.authenticationButton &&
          this.userIsAuthenticated
        ) {
          await this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
            context: this,
            params: {
              name: 'Log in - Log in intent',
              category: 'Log in',
              action: 'Log in',
              label: 'Log in intent',
              misc: [
                {
                  type: 'location',
                  value: this.block?.ctaButton?.trackingLocation,
                },
              ],
            },
          })
        } else {
          await this.$store.dispatch(AppActions.TRACK_SIGN_UP_EVENT, {
            context: this,
            location: this.block?.ctaButton?.trackingLocation,
            link: this.block?.ctaButton?.link || null,
          })
        }
      }
    },

    salesButtonClicked() {
      trackLinkedinAds('book-demo')
      this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
        context: this,
        params: {
          name: 'Demo - Request Demo',
          category: 'Goal completion',
          action: 'Request Demo',
          label: 'homepage_hero',
          misc: [{ type: 'location', value: 'homepage_hero_section' }],
        },
      })

      // @ts-ignore
      this.$refs.salesDialogRef?.talkToSalesClicked()
    },
  },
})
