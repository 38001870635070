var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"common-general-hero__wrapper px-5",class:{ 'common-general-hero__custom-bg gradient-container': _vm.block?.customBackground },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('div',{staticClass:"d-flex flex-column flex-xl-row common-general-hero__parent"},[_c('div',{staticClass:"common-general-hero__container d-flex flex-column"},[_c('div',{staticClass:"common-general-hero__details d-flex flex-column"},[_c('h1',{staticClass:"common-general-hero__title m-title",domProps:{"innerHTML":_vm._s(_vm.block?.title)}}),_vm._v(" "),_c('div',{staticClass:"common-general-hero__paragraph-container d-flex flex-column"},[_c('h2',{staticClass:"common-general-hero__paragraph m-paragraph d-flex flex-column justify-content-center justify-content-xl-start",domProps:{"innerHTML":_vm._s(_vm.block?.subtitle)}}),_vm._v(" "),_c('p',{staticClass:"common-general-hero__paragraph m-paragraph d-flex justify-content-center justify-content-xl-start",domProps:{"innerHTML":_vm._s(_vm.block?.paragraph)}})])]),_vm._v(" "),_c('div',{staticClass:"common-general-hero__buttons-container d-flex justify-content-center justify-content-xl-start"},[(_vm.block?.ctaButton?.text)?_c('div',{staticClass:"common-general-hero__button d-flex justify-content-center justify-content-xl-start"},[_c('v-btn',{attrs:{"depressed":"","aria-label":_vm.block?.ctaButton?.text,"height":_vm.block?.ctaButton?.height,"width":_vm.block?.ctaButton?.width,"color":_vm.block?.ctaButton?.color,"outlined":_vm.block?.ctaButton?.outlined,"href":_vm.block?.ctaButton?.authenticationButton
                ? _vm.heroButtonLink
                : _vm.block?.ctaButton?.link,"target":_vm.isExternalLink(
                _vm.block?.ctaButton?.authenticationButton
                  ? _vm.heroButtonLink
                  : _vm.block?.ctaButton?.link,
                _vm.$config.nuxtBaseURL
              )
                ? '_blank'
                : ''},on:{"click":_vm.heroButtonClicked}},[_c('span',{style:({ color: _vm.block?.ctaButton?.textColor }),domProps:{"innerHTML":_vm._s(_vm.block?.ctaButton?.text)}})])],1):_vm._e(),_vm._v(" "),(_vm.block?.salesButton?.text)?_c('div',{staticClass:"common-general-hero__button"},[_c('v-btn',{style:({ padding: '16px 36px' }),attrs:{"depressed":"","aria-label":_vm.block?.salesButton?.text,"height":"56","color":"primary","outlined":_vm.block?.salesButton?.outlined},on:{"click":function($event){return _vm.salesButtonClicked()}}},[_vm._v("\n            "+_vm._s(_vm.block?.salesButton?.text)+"\n          ")])],1):_vm._e()])]),_vm._v(" "),(_vm.block?.image)?_c('div',{staticClass:"common-general-hero__video-wrapper align-self-center align-self-xl-start"},[_c('nuxt-img',{ref:"videoPlayer",staticClass:"common-general-hero__video",attrs:{"alt":"commonHeroImage","src":_vm.block?.image,"loading":_vm.commonHeroImageLoading}})],1):_vm._e()]),_vm._v(" "),_c('common-sales-dialog',{ref:"salesDialogRef",attrs:{"dialogId":_vm.block?.salesButton?.dialogId,"dialogType":_vm.block?.salesButton?.dialogType}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }